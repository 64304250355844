import React from 'react'
import PropTypes from 'prop-types'

import PageWrapper from '../templates/PageWrapper'
import useSiteData from '../hooks/useSiteData'

import { Link, graphql } from 'gatsby'

const ProjectsIndex = ({ data }) => {
  const siteMetadata = useSiteData()
  const { edges: posts } = data.allMdx

  return (
    <PageWrapper title={`Projects — ${siteMetadata.siteTitle}`}>
      <h1>Projects</h1>

      <ul>
        {posts.map(({ node: post }) => (
          <li key={post.id}>
            <Link to={post.fields.slug}>
              <h2>{post.frontmatter.title}</h2>
            </Link>
            <p>{post.excerpt}</p>
          </li>
        ))}
      </ul>
    </PageWrapper>
  )
}

ProjectsIndex.propTypes = {
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query projectsIndex {
    allMdx {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ProjectsIndex
